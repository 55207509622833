<template>
  <div>
      <CCard class="b_card col-md-10 mt-2">
            <!-- <CRow class="mt-2 ml-2">
            <h2 class="font-weight-bold">Clicks :</h2>
            </CRow> -->
            <!-- <hr style="border: 1px solid black;width:97%;">   -->
            <!-- Block Harmful Application -->
          <CRow class="text-center"> 
            <CCol class="col-md-6 col-12">
             <CRow>
              <label class="label col-md-12">
                <span><CIcon name="cil-arrow-thick-to-bottom" ></CIcon></span>
                    Block
                    <b>Harmfull Applications</b> traffic
                  </label>
              <!-- Harmfull Applications -->
              <div class="text-left p-1 m-1" style="background: #e2e2e2;" @click="checkSecurity()">
                <CInputCheckbox class="col-md-12"  value="true" label="select all"
                  :checked.sync="allharmfulApp"
                  @change="mainObject.securityConfig.harmfulApp.automatedSoftware = mainObject.securityConfig.harmfulApp.automatedSoftwarel2 = mainObject.securityConfig.harmfulApp.automatedSoftwarel3 = mainObject.securityConfig.harmfulApp.waspaList = mainObject.securityConfig.harmfulApp.deviceAnomalies = allharmfulApp"></CInputCheckbox>
              <!-- automatedSoftware level 1 -->
              <img title="BOT which emulate user behaviour"
              @click="mainObject.securityConfig.harmfulApp.automatedSoftware = mainObject.securityConfig.harmfulApp.automatedSoftwarel2 = mainObject.securityConfig.harmfulApp.automatedSoftwarel3 = false" v-if="mainObject.securityConfig.harmfulApp.automatedSoftware"
                class="col-md-4 col-12 border_select"
                src="security/with_text/selected/Automated_Software_Level_1_selected.png"
              />
              <img title="BOT which emulate user behaviour"
                v-else @click="mainObject.securityConfig.harmfulApp.automatedSoftware = true"
                class="col-md-4 col-12 border_notselect"
                src="security/with_text/notselected/Automated_Software_Level_1_non_selected.png"
              />

               <!-- automatedSoftware level 2 -->
              <img title="BOT which emulate user behaviour"
              @click="mainObject.securityConfig.harmfulApp.automatedSoftware = mainObject.securityConfig.harmfulApp.automatedSoftwarel2 = mainObject.securityConfig.harmfulApp.automatedSoftwarel3  = false" v-if="mainObject.securityConfig.harmfulApp.automatedSoftwarel2 "
                class="col-md-4 col-12 border_select"
                src="security/with_text/selected/Automated_Software_Level_2_selected.png"
              />
              <img title="BOT which emulate user behaviour"
                v-else @click="mainObject.securityConfig.harmfulApp.automatedSoftware = mainObject.securityConfig.harmfulApp.automatedSoftwarel2 =true"
                class="col-md-4 col-12 border_notselect"
                src="security/with_text/notselected/Automated_Software_Level_2_non_selected.png"
              />

               <!-- automatedSoftware level 3 -->
              <img title="BOT which emulate user behaviour"
              @click="mainObject.securityConfig.harmfulApp.automatedSoftware = mainObject.securityConfig.harmfulApp.automatedSoftwarel2 = mainObject.securityConfig.harmfulApp.automatedSoftwarel3  = false" v-if="mainObject.securityConfig.harmfulApp.automatedSoftwarel3 "
                class="col-md-4 col-12 border_select"
                src="security/with_text/selected/Automated_Software_Level_3_selected.png"
              />
              <img title="BOT which emulate user behaviour"
                v-else @click="mainObject.securityConfig.harmfulApp.automatedSoftware = mainObject.securityConfig.harmfulApp.automatedSoftwarel2 = mainObject.securityConfig.harmfulApp.automatedSoftwarel3 = true"
                class="col-md-4 col-12 border_notselect"
                src="security/with_text/notselected/Automated_Software_Level_3_non_selected.png"
              />
            
            <!-- WASPA list in South Africa -->
              <img title="List of application & website flagged by WASPA"
                class="mt-2 col-md-4 col-12 border_select" @click="mainObject.securityConfig.harmfulApp.waspaList=false" v-if="mainObject.securityConfig.harmfulApp.waspaList"
                src="security/with_text/selected/waspa_list_in_soth_africa_selected.png"
              />
              <!-- <span class="text-center">WASPA list in South Africa</span> -->
              
              <img title="List of application & website flagged by WASPA"
                v-else class="mt-2 col-md-4 col-12 border_notselect" @click="mainObject.securityConfig.harmfulApp.waspaList=true"
                src="security/with_text/notselected/waspa_list_in_soth_africa_notselected.png"
              />
              <!-- <span class="text-muted text-center">WASPA list in South Africa</span> -->

               <!-- Device Anomalies-->
              <img title="Device Suspected/Infected by malware"
                class="col-md-4 col-12 border_select" @click="mainObject.securityConfig.harmfulApp.deviceAnomalies=false" v-if="mainObject.securityConfig.harmfulApp.deviceAnomalies"
                src="security/with_text/selected/DeviceAnomalies_selected.png"
              />
              <!-- <span class="text-center">Block Browser Tempering</span> -->
              
              <img title="Device Suspected/Infected by malware"
                v-else class="col-md-4 col-12 border_notselect" @click="mainObject.securityConfig.harmfulApp.deviceAnomalies=true"
                src="security/with_text/notselected/DeviceAnomalies_notselected.png"
              />
              <!-- <span class="text-muted text-center">Block Browser Tempering</span> -->
              </div>
            </CRow>


          <!-- Block Proxy Traffic -->
            <CRow class="mt-3">
              <label class="label col-md-12">
                <span><CIcon name="cil-arrow-thick-to-bottom" ></CIcon></span>
                    Block
                    <b>Proxy</b> traffic
                  </label>
              <!-- Block Web Proxy traffic -->
              <div class="text-left p-1 m-1" style='background: #e2e2e2;' @click="checkSecurity()">
                <CInputCheckbox class="col-md-12"  value="true" label="select all"
                  :checked.sync="allproxyTraffic"
                  @change="mainObject.securityConfig.proxyTraffic.webProxy = mainObject.securityConfig.proxyTraffic.vpnProxy = mainObject.securityConfig.proxyTraffic.operaProxy = mainObject.securityConfig.proxyTraffic.googleProxy = allproxyTraffic"></CInputCheckbox>
              <img
                class="col-md-4 col-12 border_select" @click="mainObject.securityConfig.proxyTraffic.webProxy=false" v-if="mainObject.securityConfig.proxyTraffic.webProxy"
                src="security/with_text/selected/block_web_proxy_traffic_selected.png"
              />
              <!-- <span class="text-center">Block Web Proxy traffic</span> -->
              
              <img
                v-else class="col-md-4 col-12 border_notselect" @click="mainObject.securityConfig.proxyTraffic.webProxy=true"
                src="security/with_text/notselected/block_web_proxy_traffic_notselected.png"
              />
              <!-- <span class="text-muted text-center">Block Web Proxy traffic</span> -->
              
              <!-- Block VPN Proxy traffic -->
              <img
                class="col-md-4 col-12 border_select" @click="mainObject.securityConfig.proxyTraffic.vpnProxy=false" v-if="mainObject.securityConfig.proxyTraffic.vpnProxy"
                src="security/with_text/selected/block_vpn_proxy_traffic_selected.png"
              />
              <!-- <span class="text-center">Block VPN Proxy traffic</span> -->
             
              <img
                v-else class="col-md-4 col-12 border_notselect" @click="mainObject.securityConfig.proxyTraffic.vpnProxy=true"
                src="security/with_text/notselected/block_vpn_proxy_traffic_notselected.png"
              />
              <!-- <span class="text-muted text-center">Block VPN Proxy traffic</span> -->
              
            
              <!-- Block Opera Mini Proxy -->
              <img
                class="mt-2 col-md-4 col-12 border_select" @click="mainObject.securityConfig.proxyTraffic.operaProxy=false" v-if="mainObject.securityConfig.proxyTraffic.operaProxy"
                src="security/with_text/selected/block_opera_mini_proxy_selected.png"
              />
              <!-- <span class="text-center">Block Opera Mini Proxy</span> -->
              
              <img
                v-else class="mt-2 col-md-4 col-12 border_notselect" @click="mainObject.securityConfig.proxyTraffic.operaProxy=true"
                src="security/with_text/notselected/block_opera_mini_proxy_notselected.png"
              />
              <!-- <span class="text-muted text-center">Block Opera Mini Proxy</span> -->
              
              <!-- Block Google Proxy -->
              <img
                class="mt-2 col-md-4 col-12 border_select" @click="mainObject.securityConfig.proxyTraffic.googleProxy=false" v-if="mainObject.securityConfig.proxyTraffic.googleProxy"
                src="security/with_text/selected/block_google_proxy_selected.png"
              />
              <!-- <span class="text-center">Block Google Proxy</span> -->
            
              <img
                v-else class="mt-2 col-md-4 col-12 border_notselect" @click="mainObject.securityConfig.proxyTraffic.googleProxy=true"
                src="security/with_text/notselected/block_google_proxy_notselected.png"
              />
              <!-- <span class="text-muted text-center">Block Google Proxy</span> -->
              </div>
            </CRow>
         </CCol> 

            
            <CCol style="border-left: 1px solid;">
            <!-- Block Iframe traffic -->
            <CRow class="">
              <label class="label col-md-12" style="">
                <span><CIcon name="cil-arrow-thick-to-bottom" ></CIcon></span>
                    Block
                    <strong>Iframe</strong> traffic
                  </label>
              <!-- Block Regular Iframe traffic -->
              <div class="text-left p-1 m-1" style='background: #e2e2e2;' @click="checkSecurity()">
                 <CInputCheckbox class="col-md-12"  value="true" label="select all"
                  :checked.sync="alliframeTraffic"
                  @change="mainObject.securityConfig.iframeTraffic.regularIFrame = mainObject.securityConfig.iframeTraffic.tinyIFrame = alliframeTraffic"></CInputCheckbox>
              <img title="Web page contains inside another Web page"
                class="col-md-4 col-12 border_select" @click="mainObject.securityConfig.iframeTraffic.regularIFrame=false" v-if="mainObject.securityConfig.iframeTraffic.regularIFrame"
                src="security/with_text/selected/block_regular_inframe_traffic_selected.png"
              />
              <!-- <span class="text-center">Block Regular Iframe traffic</span> -->
              
              <img title="Web page contains inside another Web page"
                v-else class="col-md-4 col-12 border_notselect" @click="mainObject.securityConfig.iframeTraffic.regularIFrame=true"
                src="security/with_text/notselected/block_regular_inframe_traffic_notselected.png"
              />
              <!-- <span class="text-muted text-center">Block Regular Iframe traffic</span> -->
              
              <!-- Block Tiny Iframe traffic -->
              <img title="Web page inside another webpage and hidden by user"
                class="col-md-4 col-12 border_select" @click="mainObject.securityConfig.iframeTraffic.tinyIFrame=false" v-if="mainObject.securityConfig.iframeTraffic.tinyIFrame"
                src="security/with_text/selected/blcok_tiny_tnframe_traffice_selected.png"
              />
              <!-- <span class="text-center">Block Tiny Iframe traffic</span> -->
              
              <img title="Web page inside another webpage and hidden by user"
                v-else class="col-md-4 col-12 border_notselect" @click="mainObject.securityConfig.iframeTraffic.tinyIFrame=true"
                src="security/with_text/notselected/blcok_tiny_tnframe_traffice_notselected.png"
              />
              <!-- <span class="text-muted text-center">Block Tiny Iframe traffic</span> -->

              </div>
            </CRow>



            <!-- Block Request Tempering traffic -->
            <CRow class="mt-3">
              <label class="label col-md-12">
                <span><CIcon name="cil-arrow-thick-to-bottom" ></CIcon></span>
                    Block
                    <strong>Request Tampering</strong> traffic
                  </label>
              <!-- Block Invalid Requests -->
              <div class="text-left p-1 m-1" style='background: #e2e2e2;' @click="checkSecurity()">
                <CInputCheckbox class="col-md-12"  value="true" label="select all"
                  :checked.sync="allrequestTempring"
                  @change="mainObject.securityConfig.requestTempring.invalidReq= mainObject.securityConfig.requestTempring.headerInjection = mainObject.securityConfig.requestTempring.invalidApp = allrequestTempring"></CInputCheckbox>
               <img
                class="col-md-4 col-12 border_select" @click="mainObject.securityConfig.requestTempring.invalidReq=false" v-if="mainObject.securityConfig.requestTempring.invalidReq"
                src="security/with_text/selected/block_invalid_requests_selected.png"
              />
              <!-- <span class="text-center">Block Invalid Requests</span> -->
              
              <img
                v-else class="col-md-4 col-12 border_notselect" @click="mainObject.securityConfig.requestTempring.invalidReq=true"
                src="security/with_text/notselected/block_invalid_requests_notselected.png"
              />
              <!-- <span class="text-muted text-center">Block Invalid Requests</span> -->
              
              
      
              
              <!-- Block MSISDN Injection traffic -->
              <img
                class="col-md-4 col-12 border_select" @click="mainObject.securityConfig.requestTempring.headerInjection=false" v-if="mainObject.securityConfig.requestTempring.headerInjection"
                src="security/with_text/selected/Block-header-injection-selected.png"
              />
              <!-- <span class="text-center">Block MSISDN Injection traffic</span> -->
              
              <img
                v-else class="col-md-4 col-12 border_notselect" @click="mainObject.securityConfig.requestTempring.headerInjection=true"
                src="security/with_text/notselected/Block-header-injection.png"
              />
              <!-- <span class="text-muted text-center">Block MSISDN Injection traffics</span> -->
             
              <!-- Block Invalid App name traffic -->
              <img
                class="mt-2 col-md-4 col-12 border_select" @click="mainObject.securityConfig.requestTempring.invalidApp=false" v-if="mainObject.securityConfig.requestTempring.invalidApp"
                src="security/with_text/selected/block_invalid_app_name_traffic_selected.png"
              />
              <!-- <span class="text-center">Block Invalid App name traffic</span> -->
              
              <img
                v-else class="mt-2 col-md-4 col-12 border_notselect" @click="mainObject.securityConfig.requestTempring.invalidApp=true"
                src="security/with_text/notselected/block_invalid_app_name_traffic_notselected.png"
              />
              <!-- <span class="text-muted text-center">Block Invalid App name traffic</span> -->
              </div>
            </CRow>
            
            <!-- Block Dangerous Ip traffic -->
            <CRow class="mt-3">
              <label class="label col-md-12">
                <span><CIcon name="cil-arrow-thick-to-bottom" ></CIcon></span>
                    Block
                    <strong>Dangerous Ip</strong> traffic
                  </label>
              <!-- Block Attack IPs-->
              <div class="text-left p-1 m-1" style='background: #e2e2e2;' @click="checkSecurity()">
                 <CInputCheckbox class="col-md-12"  value="true" label="select all"
                  :checked.sync="alldangerIp"
                  @change="mainObject.securityConfig.dangerIp.attackIp = mainObject.securityConfig.dangerIp.abuseIp = mainObject.securityConfig.dangerIp.malwareIp = alldangerIp"></CInputCheckbox>
              <img
                class="col-md-4 col-12 border_select" @click="mainObject.securityConfig.dangerIp.attackIp=false" v-if="mainObject.securityConfig.dangerIp.attackIp"
                src="security/with_text/selected/block_attack_ips_selected.png"
              />
              <!-- <span class="text-center">Block Attack IPs</span> -->
              
              <img
                v-else class="col-md-4 col-12 border_notselect" @click="mainObject.securityConfig.dangerIp.attackIp=true"
                src="security/with_text/notselected/block_attack_ips_notselected.png"
              />
              <!-- <span class="text-muted text-center">Block Attack IPs</span> -->
              
              <!-- Block Abuse IPs -->
              <img
                class="col-md-4 col-12 border_select" @click="mainObject.securityConfig.dangerIp.abuseIp=false" v-if="mainObject.securityConfig.dangerIp.abuseIp"
                src="security/with_text/selected/block_abuse_ips_selected.png"
              />
              <!-- <span class="text-center">Block Abuse IPs</span> -->
            
              <img
                v-else class="col-md-4 col-12 border_notselect" @click="mainObject.securityConfig.dangerIp.abuseIp=true"
                src="security/with_text/notselected/block_abuse_ips_notselected.png"
              />
              <!-- <span class="text-muted text-center">Block Abuse IPs</span> -->
              

              <!-- Block Malware IPs -->
              <img
                class="col-md-4 col-12 border_select" @click="mainObject.securityConfig.dangerIp.malwareIp=false" v-if="mainObject.securityConfig.dangerIp.malwareIp"
                src="security/with_text/selected/block_malware_ips_selected.png"
              />
              <!-- <span class="text-center">Block Malware IPs</span> -->
              
              <img
                v-else class="col-md-4 col-12 border_notselect" @click="mainObject.securityConfig.dangerIp.malwareIp=true"
                src="security/with_text/notselected/block_malware_ips_notselected.png"
              />
              <!-- <span class="text-muted text-center">Block Malware IPs</span> -->
            </div> 
            </CRow>

            </CCol>
            </CRow>
            <hr class="text-muted" style="border: 1px solid black;width:97%;"> 
            <div class="p-1 m-1" style='background: #e2e2e2;' @click="checkSecurity()">
               <CInputCheckbox class="col-md-12"  value="true" label="select all"
                  :checked.sync="allextra"
                  @change="mainObject.securityConfig.adultKeyword.adultkeyword = mainObject.securityConfig.nonPlayStoreApk.nonPlayStoreApk = mainObject.securityConfig.susDevConvDist.susDevConvDist = mainObject.securityConfig.harmfulApp.unseen = allextra"></CInputCheckbox>
              <!-- Block Adult keywords in headers traffic -->
              <img
                class="col-md-3 col-12 border_select" @click="mainObject.securityConfig.adultKeyword.adultkeyword=false" v-if="mainObject.securityConfig.adultKeyword.adultkeyword"
                src="security/with_text/selected/block_adult_keyworlds_selected.png"
              />
              <!-- <span class="text-center">Block Adult keywords in headers traffic</span> -->
              
              <img
                v-else class="col-md-3 col-12 border_notselect" @click="mainObject.securityConfig.adultKeyword.adultkeyword=true"
                src="security/with_text/notselected/block_adult_keyworlds_notselected.png"
              />
              <!-- <span class="text-muted text-center">Block Adult keywords in headers traffic</span> -->
              

              <!-- Block Non Playstore APK traffic -->
              <img
                class="col-md-3 col-12 border_select" @click="mainObject.securityConfig.nonPlayStoreApk.nonPlayStoreApk=false" v-if="mainObject.securityConfig.nonPlayStoreApk.nonPlayStoreApk"
                src="security/with_text/selected/block_non_playstore_apk_traffic_selected.png"
              />
              <!-- <span class="text-center">Block Non Playstore APK traffic</span> -->
              
              <img
                v-else class="col-md-3 col-12 border_notselect" @click="mainObject.securityConfig.nonPlayStoreApk.nonPlayStoreApk=true"
                src="security/with_text/notselected/block_non_playstore_apk_traffic_notselected.png"
              />
              <!-- <span class="text-muted text-center">Block Non Playstore APK traffic</span> -->
              

              <!-- Block Suspicious device conversions distribution traffic -->
              <img
                class="col-md-3 col-12 border_select" @click="mainObject.securityConfig.susDevConvDist.susDevConvDist=false" v-if="mainObject.securityConfig.susDevConvDist.susDevConvDist"
                src="security/with_text/selected/block_suspicious_device_selected.png"
              />
              <!-- <span class="text-center">Block Suspicious device conversions distribution traffic</span> -->
              
              <img
                v-else class="col-md-3 col-12 border_notselect" @click="mainObject.securityConfig.susDevConvDist.susDevConvDist=true"
                src="security/with_text/notselected/block_suspicious_device_notselected.png"
              />

               <!-- Unseen -->
              <img title="Page not seen by user"
                class="col-md-3 col-12 border_select" @click="mainObject.securityConfig.harmfulApp.unseen=false" v-if="mainObject.securityConfig.harmfulApp.unseen"
                src="security/with_text/selected/hidden_traffic_selected.png"
              />
              <!-- <span class="text-center">Block Tiny Iframe traffic</span> -->
              
              <img title="Page not seen by user"
                v-else class="col-md-3 col-12 border_notselect" @click="mainObject.securityConfig.harmfulApp.unseen=true"
                src="security/with_text/notselected/hidden_traffic_notselected.png"
              />
              <!-- <span class="text-muted text-center">Block Tiny Iframe traffic</span> -->
              <!-- <span class="text-muted text-center">Block Suspicious device conversions distribution traffic</span> -->
               <!-- <img
                v-if="mainObject.securityConfig.clickPage.clickPage" class="col-md-3 col-12 border_notselect" @click="mainObject.securityConfig.clickPage.clickPage=false"
                src="security/with_text/selected/click_page_selected.png"
              /> -->
              <!-- <span class="text-muted text-center">Block Suspicious device conversions distribution traffic</span> -->
               <!-- <img
                v-else class="col-md-3 col-12 border_notselect" @click="mainObject.securityConfig.clickPage.clickPage=true"
                src="security/with_text/notselected/click_page_notselected.png"
              /> -->
              <!-- <span class="text-muted text-center">Block Suspicious device conversions distribution traffic</span> -->

              
            </div>
            <hr class="text-muted" style="border: 1px solid black;width:97%;"> 
            <CRow class="ml-2 mt-2">
            <h2 class="font-weight-bold">Other Settings :</h2>
            </CRow>
            
            <div class="text-left p-1 m-1" style='background: #e2e2e2;' @click="checkSecurity()">
            <CInputCheckbox class="col-md-12"  value="true" label="select all"
                  :checked.sync="allReport"
                  @change="mainObject.securityConfig.high_risk = mainObject.securityConfig.suspicious = mainObject.securityConfig.forceDefaultBrowser = allReport"></CInputCheckbox>
            <!-- Don't report high risk conversions to traffic source -->
              <img
                class="col-md-3 col-12 border_select" @click="mainObject.securityConfig.high_risk=false" v-if="mainObject.securityConfig.high_risk"
                src="security/with_text/selected/don’t_report_high_risk_selected.png"
              />
              <!-- <CRow class="text-left col-md-12">Don't report high risk conversions to traffic source</CRow> -->
              
             <img
                v-else class="col-md-3 col-12 border_notselect" @click="mainObject.securityConfig.high_risk=true"
                src="security/with_text/notselected/don’t_report_high_risk_notselected.png"
              />
              <!-- <CRow class="text-muted text-left col-md-12">Don't report high risk conversions to traffic source</CRow> -->
             
            <!-- Don't report suspicious conversions to traffic source-->
              <img
                class="col-md-3 col-12 border_select" @click="mainObject.securityConfig.suspicious=false" v-if="mainObject.securityConfig.suspicious"
                src="security/with_text/selected/don’t_report_suspicious_selected.png"
              />
              <!-- <CRow class="text-left col-md-12">Don't report suspicious conversions to traffic source</CRow> -->
              
             <img
                v-else class="col-md-3 col-12 border_notselect" @click="mainObject.securityConfig.suspicious=true"
                src="security/with_text/notselected/don’t_report_suspicious_notselected.png"
              />


              <!-- forced_default_browser_selected-->
              <img
                class="col-md-3 col-12 border_select" @click="mainObject.securityConfig.forceDefaultBrowser=false" v-if="mainObject.securityConfig.forceDefaultBrowser"
                src="security/with_text/selected/forced_default_browser_selected.png"
              />

             <img
                v-else class="col-md-3 col-12 border_notselect" @click="mainObject.securityConfig.forceDefaultBrowser=true"
                src="security/with_text/notselected/forced_default_browser_notselected.png"
              />
              
            </div>
          </CCard>
  </div>
</template>




<script>
export default {
    name: 'MasterSecurity',
    props:{
      mainObject:Object,
      security_show:Boolean
    },
    data(){
      return {
        allharmfulApp:false,
        alliframeTraffic:false,
        allproxyTraffic:false,
        allrequestTempring:false,
        alldangerIp:false,
        allextra:false,
        allReport:false,
      }
    },
      watch: {
        mainObject(value){
          this.allharmfulApp = this.alliframeTraffic = this.allproxyTraffic = this.allrequestTempring = this.alldangerIp = this.allextra = this.allReport = false
          if(this.mainObject.securityConfig.harmfulApp.automatedSoftware = this.mainObject.securityConfig.harmfulApp.automatedSoftwarel2 = this.mainObject.securityConfig.harmfulApp.automatedSoftwarel3 = this.mainObject.securityConfig.harmfulApp.waspaList = this.mainObject.securityConfig.harmfulApp.deviceAnomalies == true){
            this.allharmfulApp = true
          }
          if(this.mainObject.securityConfig.iframeTraffic.regularIFrame = this.mainObject.securityConfig.iframeTraffic.tinyIFrame == true){
            this.alliframeTraffic=true
          }
          if(this.mainObject.securityConfig.proxyTraffic.webProxy = this.mainObject.securityConfig.proxyTraffic.vpnProxy = this.mainObject.securityConfig.proxyTraffic.operaProxy = this.mainObject.securityConfig.proxyTraffic.googleProxy == true ){
            this.allproxyTraffic = true
          }
          if(this.mainObject.securityConfig.requestTempring.invalidReq= this.mainObject.securityConfig.requestTempring.headerInjection = this.mainObject.securityConfig.requestTempring.invalidApp == true){
            this.allrequestTempring = true
          }
          if(this.mainObject.securityConfig.dangerIp.attackIp = this.mainObject.securityConfig.dangerIp.abuseIp = this.mainObject.securityConfig.dangerIp.malwareIp == true){
            this.alldangerIp = true
          }
          if(this.mainObject.securityConfig.adultKeyword.adultkeyword = this.mainObject.securityConfig.nonPlayStoreApk.nonPlayStoreApk = this.mainObject.securityConfig.susDevConvDist.susDevConvDist = this.mainObject.securityConfig.harmfulApp.unseen == true){
            this.allextra = true
          }
          if(this.mainObject.securityConfig.high_risk = this.mainObject.securityConfig.suspicious = this.mainObject.securityConfig.forceDefaultBrowser == true){
            this.allReport = true
          }
        },
        security_show(value){
          this.checkSecurity()
        }
      },
      methods:{
        checkSecurity(){
          this.allharmfulApp = this.alliframeTraffic = this.allproxyTraffic = this.allrequestTempring = this.alldangerIp = this.allextra = this.allReport = false
          if((this.mainObject.securityConfig.harmfulApp.automatedSoftware && this.mainObject.securityConfig.harmfulApp.automatedSoftwarel2 && this.mainObject.securityConfig.harmfulApp.automatedSoftwarel3 && this.mainObject.securityConfig.harmfulApp.waspaList && this.mainObject.securityConfig.harmfulApp.deviceAnomalies) == true){
            this.allharmfulApp = true
          }
          if((this.mainObject.securityConfig.iframeTraffic.regularIFrame && this.mainObject.securityConfig.iframeTraffic.tinyIFrame) == true){
            this.alliframeTraffic= true
          }
          if((this.mainObject.securityConfig.proxyTraffic.webProxy && this.mainObject.securityConfig.proxyTraffic.vpnProxy && this.mainObject.securityConfig.proxyTraffic.operaProxy && this.mainObject.securityConfig.proxyTraffic.googleProxy) == true ){
            this.allproxyTraffic = true
          }
          if((this.mainObject.securityConfig.requestTempring.invalidReq && this.mainObject.securityConfig.requestTempring.headerInjection && this.mainObject.securityConfig.requestTempring.invalidApp) == true){
            this.allrequestTempring = true
          }
          if((this.mainObject.securityConfig.dangerIp.attackIp && this.mainObject.securityConfig.dangerIp.abuseIp && this.mainObject.securityConfig.dangerIp.malwareIp) == true){
            this.alldangerIp = true
          }
          if((this.mainObject.securityConfig.adultKeyword.adultkeyword && this.mainObject.securityConfig.nonPlayStoreApk.nonPlayStoreApk && this.mainObject.securityConfig.susDevConvDist.susDevConvDist && this.mainObject.securityConfig.harmfulApp.unseen) == true){
            this.allextra = true
          }
          if((this.mainObject.securityConfig.high_risk && this.mainObject.securityConfig.suspicious && this.mainObject.securityConfig.forceDefaultBrowser) == true){
            this.allReport = true
          }
        }
      }
}
</script>