<template>
  <div>
    <CCard class="col-md-12">
      <CRow class="p-3">
       <h4 class="color-light ml-2 page_title"><span class="ul_text">MA</span>STER SECURITY</h4>
       <div class="card-header-actions" @click="openModel()">
            <small class="text-muted ml-2"
              ><CIcon name="cil-info"/></small></div>
      </CRow>
      <CCardBody>
    <CRow class="col-md-12">
        <!-- <pre>{{mainObject}}</pre> -->
          <p class="mt-3" style="font-size: 14px;">
            When master configuration is enabled, it will be applied to all
            campaigns without a security configuration. This means that if you
            have a campaign running with the intent of only analysing and not
            blocking traffic, it will start block traffic based in this Master
            Configuration.
          </p>
          <p style="font-size: 14px;">
            This feature can be used to force users without edit permissions
            over Security Configurations to use this Master Configuration when
            creating a new campaign.
          </p>
          <p style="font-size:14px;">
            Please note that enabling, disabling or changing the configuration
            of this feature will affect all running campaigns
          </p>
          <CRow class="col-md-12">
            <CSwitch
              class="mr-1"
              color="success"
              :checked="enable"
              shape="pill"
              @update:checked="securitySwitch"
            />
          <label class="ml-2"><strong>Enable Master Security</strong></label>
          </CRow>
          <master-security
            :mainObject="mainObject" :security_show="security_show"></master-security>
        </CRow>
        <CButton type="submit" v-on:click="onSubmit" color="success"
          >Save</CButton
        >
      </CCardBody></CCard>

       <!-- Show Model -->
    <CModal
      title="Master Security Details"
      :show.sync="show_details"
      size="lg"
      :centered="true"
      scrollable
      color="dark"
    >
    <h1>Security</h1>
        <h4>Clicks</h4>
        <label class="mt-3">
          If you choose to block some of these reasons, traffic won't be
          redirected to the offer. Instead of that, it will be redirected to
          your
          <b style="color:blue;">fallback URL.</b>
        </label>
        <h5 class="mt-2">Harmful Applications</h5>
        <label
          >Harmful application (HA) refers to any software attempting to
          generate fraudulent income.</label
        >
        <label class="mt-3"
          >In many cases, the HA is a malicious app on a user's device which
          attempts to generate profits by subscribing the user to premium
          services or downloading other apps. This activity occurs without the
          user's knowledge and the malicious app often masks itself as a
          legitimate app. Malicious web sites can also perform similar
          fraud.</label
        >
        <label class="mt-3"
          >Other common HAs are automated pieces of software running on servers,
          known as bots. Bots can generate huge amounts of fake traffic in an
          attempt to generate CPC income, video views, fill in lead generation
          forms or subscribe users to premium services.</label
        >
        <h5 class="mt-2">Iframe</h5>
        <label
          >An iframe (short for inline frame) is an HTML element that allows an
          external webpage to be embedded in an HTML document. If you are not
          very careful when allowing your pages to be shown within somebody
          else's HTML page, there is a considerable security risk. fraudAvoid
          can block traffic if we find that your page is being shown inside an
          Iframe.</label
        >
        <label class="mt-3"
          >We consider regular iframe traffic when we detect that the dimensions
          of the iframe loading the page is big enough to make sense. On the
          contrary, we categorise traffic as tiny iframe when its dimensions are
          so small that doesn't make sense.</label
        >
        <label class="mt-3">
          The most common security threat involving Iframes is
          <label style="color:blue">clickjacking.</label> Clickjacking, also
          known as a 'UI redress attack', is when an attacker uses multiple
          transparent or opaque layers to trick a user into clicking on a button
          or link on another page when they were intending to click on the the
          top level page. For help on avoiding clickjacking, see the
          <label style="color:blue">Clickjacking Defense Cheat Sheet.</label>
        </label>
        <h5 class="mt-2">Proxy/VPN/Datacenter traffic</h5>
        <label
          >Traffic originating from a proxy, VPN or datacenter will anonymise
          the actual user making the visit to your website.</label
        >
        <label class="mt-3">
          <b>Proxy</b>
        </label>
        <label
          >A proxy server is basically another computer which serves as a hub
          through which internet requests are processed. By connecting through
          one of these servers, your computer sends your requests to the server
          which then processes your request and returns what you were wanting.
          In this way it serves as an intermediary between your home machine and
          the rest of the computers on the internet. Proxies are used for a
          number of reasons such as to filter web content, to go around
          restrictions such as parental blocks, to screen downloads and uploads
          and to provide anonymity when surfing the internet.</label
        >
        <label class="mt-3">
          <b>VPN</b>
        </label>
        <label
          >A Virtual Private Network is a connection method used to add security
          and privacy to private and public networks, like WiFi Hotspots and the
          Internet. Virtual Private Networks are most often used by corporations
          to protect sensitive data. However, using a personal VPN is
          increasingly becoming more popular as more interactions that were
          previously face-to-face transition to the Internet. Privacy is
          increased with a Virtual Private Network because the user's initial IP
          address is replaced with one from the Virtual Private Network
          provider. Subscribers can obtain an IP address from any gateway city
          the VPN service provides. For instance, you may live in San Francisco,
          but with a Virtual Private Network, you can appear to live in
          Amsterdam, New York, or any number of gateway cities.</label
        >
        <label class="mt-3">
          <b>Datacenter</b>
        </label>
        <label
          >A data center (or datacenter) is a facility composed of networked
          computers and storage that businesses or other organizations use to
          organize, process, store and disseminate large amounts of data. A
          business typically relies heavily upon the applications, services and
          data contained within a data center, making it a focal point and
          critical asset for everyday operations.</label
        >

        <p>Opera Mini Proxy</p>
        <label
          >Opera traffic in itself is not suspicious, but you may want to block
          it. You can do it by selecting this option.</label
        >

        <p>Google Proxy</p>
        <label
          >Check this option to block Google Data Saver traffic. If Google Data
          Saver can’t access yourCampaign, Chrome will try accessing
          normally.</label
        >

        <h5 class="mt-2">Request Tampering</h5>
        <label
          >fraudAvoid detects different types of tampered requests and
          abnormal behaviours.</label
        >
        <label class="mt-3">
          HTTP header injection is a general class of web application security
          vulnerability which occurs when Hypertext Transfer Protocol (HTTP)
          headers are dynamically generated. fraudAvoid specifically looks for
          any headers containing
          <a href>MSISDN</a> information, which is a clear indication of fraud.
        </label>

        <h5 class="mt-2">Device Tampering traffic</h5>
        <label
          >The User Agent header identifies the device and software being used
          to visit the web page. In an attempt to hide their activity and access
          restricted content, fraudsters falsely identify the software they are
          using (i.e. the web browser). For example, a single bot running on a
          Linux server will pose as 30 different mobile browsers in order to
          access content meant for mobile devices while hiding the fact that all
          the traffic originates from the same device.</label
        >
        <label class="mt-3"
          >fraudAvoid can detect cases where the User Agent information is not
          consistent with the device features, and block the traffic.</label
        >

        <h5 class="mt-2">Dangerous IP</h5>
        <label
          >An IP address is a label which is used to identify one or more
          devices on a computer network, such as the internet. It is comparable
          to a postal address. Every device which accesses your web pages has an
          IP address.</label
        >
        <label class="mt-3"
          >fraudAvoid curates its own lists as well as subscribes to external
          lists of dangerous IP addresses. Dangerous IP addresses are those
          which have been known to generate spam, launch abuse and malware, or
          are know to be unreachable.</label
        >

        <h5 class="mt-2">Adult keywords in headers</h5>
        <label>
          fraudAvoid can block traffic with adult (XXX) keywords found in the
          <a href>HTTP Headers</a>. This can help protect mainstream offers from
          receiving adult traffic.
        </label>

        <h5 class="mt-2">Non PlayStore APK</h5>
        <label
          >The application hasn't been installed from the official Google
          PlayStore. Google's PlayStore security and health checks are not being
          passed for this apps.</label
        >

        <h5 class="mt-2">Suspicious device conversions distribution</h5>
        <label
          >fraudAvoid analyzes the device distribution of Campaign
          conversions. If this filter is selected, fraudAvoid will block
          visits from suspicious devices. You can choose between agressive or
          lenient settings.</label
        >
        <label class="mt-3">
          Please note that in order to use this feature, you
          <b>must be reporting your conversions</b> to us. To do this, you
          should use the offer postback.
        </label>

        <h4 class="mt-2">Conversions</h4>
        <label>
          If you choose
          <b>don’t report high risk or suspicious conversions</b>, we won’t send
          any postbacks to traffic sources when the conversion is deemed to have
          medium risk or to be suspicious. This way you won’t incur costs for
          these conversions.
        </label>
    <template #footer>
        <CButton @click="show_details = false" color="danger">Close</CButton>
        <!-- <CButton @click="darkModal = false" color="success">Accept</CButton> -->
      </template>
    </CModal>
  </div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import SettingService from "./../../services/settingControlService";
import MasterSecurity from "./../../components/setting/MasterSecurity";
export default {
  components: {
    MasterSecurity
  },
  data() {
    return {
      show_details:false,
      mainObject: {
        securityConfig: {
          proxyTraffic: {
            webProxy: false,
            vpnProxy: false,
            operaProxy: false,
            googleProxy: false,
          },
          adultKeyword: { adultkeyword: false },
          nonPlayStoreApk: { nonPlayStoreApk: false },
          susDevConvDist: { susDevConvDist: false },
          dangerIp: { attackIp: false, abuseIp: false, malwareIp: false },
          requestTempring: {
            invalidReq: false,
            headerInjection: false,
            invalidApp: false,
          },
          harmfulApp: {
            automatedSoftware: false,
            automatedSoftwarel2: false,
            automatedSoftwarel3: false,
            waspaList: false,
            deviceAnomalies: false,
            unseen : false
          },
          iframeTraffic: { regularIFrame: false, tinyIFrame: false },
          high_risk:false,
          suspicious:false,
          clickPage: { clickPage : false },
          forceDefaultBrowser:false
        },
      },
      enable: false,
      security_show:false
    };
  },
  mounted() {
    if(window.localStorage.getItem('user') && window.localStorage.getItem('status') != 'true'){
          this.$router.push('/')
        }
    else{
    this.getAllData();
    }
  },
  computed: {
    validation() {
      //   if (this.mainObject.offername == "") {
      //     return false;
      //   }
    },
  },
  methods: {
    openModel(){
      this.show_details = true
    },
    async securitySwitch() {
      try {
        if (this.enable == true) {
          this.enable = false;
        } else {
          this.enable = true;
        }
        let master_security = {
          enable: this.enable
        };
        let response = await SettingService.changesetting(master_security);
        if(response.result){
          Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        }
        else{
          Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        }
      } catch (e) {
          // Swal.fire({title: 'Error!',text: "connection error",icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
        // this.$emit("ready", false);
        // this.errorNotify("Error", "connection error", "danger");
      }
    },
    async getAllData() {
      try {
        let response = await SettingService.getsetting();

        if (response.message) {

          for(let f in this.mainObject.securityConfig) {
              if(typeof this.mainObject.securityConfig[f] == "object") {
                for(let s in this.mainObject.securityConfig[f]) {
                    this.mainObject.securityConfig[f][s] = response.message.securityConfig[f][s] || false
                 }
              } else {
                this.mainObject.securityConfig[f] = response.message.securityConfig[f] || false
              }
          }
          // this.mainObject.securityConfig = response.message.securityConfig;
          this.enable = response.message.enable;
        }
      } catch (e) {
        console.log('connection error')
        // this.errorNotify("Error", "connection error", "danger");
      }
      this.security_show =  true
    },
    async onSubmit() {
      try {
        let master_security = {
          securityConfig: this.mainObject.securityConfig,
          enable: this.enable
        };
        let response = await SettingService.changesetting(master_security);
        if(response.result){
          Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        }
        else{
          Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        }
      } catch (e) {
          // Swal.fire({title: 'Error!',text: "connection error",icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
        // this.$emit("ready", false);
        // this.errorNotify("Error", "connection error", "danger");
      }
    },
  },
};
</script>

<style scoped>
img{
      height: auto;
}
.label {
  text-align: left;
  font-weight: 400;
  color: black;
  font-size: 12px;
  text-decoration: underline;
}
.border_select{
  /* border:2px solid rgb(0, 17, 255);
  padding: 2px; */
}
.border_notselect{
  /* border:none;
  border:1px solid rgb(204, 204, 204);
  padding: 2px; */
}

</style>
